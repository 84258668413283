<template>
  <v-container fluid>
    <v-toolbar rounded class="mb-2">
      <v-toolbar-title class="text-h5" elevation="20">
        {{ isSearch ? 'Résultat de la recherche de module et sous-module' : (show ? 'Gestion des sous-modules' : 'Gestion des modules') }}
      </v-toolbar-title>
      <v-spacer></v-spacer> 
      <v-btn color="primary" small @click="openModalModule" title="Creer un module">Créer</v-btn>
    </v-toolbar> 
    
    <div class="d-flex align-center justify-space-between"> 
      <div class="d-flex align-center" style="gap: 1rem;"> 
        <v-text-field 
          solo
          hide-details
          single-line
          dense
          label='Module ou sous-module'
          append-icon="mdi-magnify"
          v-model="search"
          @keyup.enter="searchEl(search)"
          @click:append="searchEl(search)"
        ></v-text-field>
        <div 
          style="max-width: 15rem;"
          class="d-flex flex-nowrap flex-row align-center justify-center"
        >
          <v-select 
              v-model="moduleSort"
              :items="sorts"
              return-object
              solo
              dense
              hide-details
              :label="$t('actions.sort')"
              @change="sortDataByTitle(moduleSort.value)"
          >
            <template #selection="{item}">
              {{ $t(item.title) }}
            </template>
            <template #item="{item}">
                {{ $t(item.title) }}
            </template>
          </v-select>
        </div>
      </div>
      
      <div class="d-flex align-center">
        <v-btn v-if="!isSearch"
          class="mr-2 elevation-2"
          @click="openListSousModule" 
          rounded
          color="primary"
          dark
        >
        {{ show ? 'Modules' : 'Sous-modules' }}
        </v-btn>
        <v-btn-toggle 
          class="elevation-2" color="primary"
          mandatory 
          borderless 
          v-model="modulesView"
        >
          <v-btn>
            <v-icon :class="{'switch-selected': modulesView == 1}">mdi-view-module</v-icon>
          </v-btn>
          <v-btn>
            <v-icon :class="{'switch-selected': modulesView == 2}">mdi-apps</v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <!-- si aucune data afficher un message -->
    <v-alert 
      v-if="dataModule.length === 0 && !show && !loading && !isBackBtn" 
      type="info" 
      outlined
    >
      Aucun module
    </v-alert>
    <v-container  v-if="!show || isSearch"
     fluid class="module-grid" :class="{'small': modulesView == 0, 'x-small': modulesView == 1}">
     <template v-if="loading && dataModule !== null && dataModule.length == 0 && !isSearch">
        <v-container 
          fluid
          v-for="i in 10" :key="i"
          class="skeleton-loader"
        >
          <v-skeleton-loader type="card" height="250">
          </v-skeleton-loader>
        </v-container>
      </template>
      <v-card 
        class="d-flex flex-column align-center justify-center"
        min-width="200"
        v-for="(item, index) in dataModule" :key="item._id" 
      > 
      <v-toolbar 
        class="mb-4"
        color="primary"
        dense
        flat
        dark
        max-height="3rem" 
        style="width: 100%;"  
      >
        <v-toolbar-title class="text-h7 text-capitalize">
            {{ item.module.module }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            @click="addSousModule(item._id, item)"
            title="Ajouter un sous-module"
            color="blue lighten-1"
        >
          <v-icon class="elevation-8">mdi-view-grid-plus</v-icon>
        </v-btn> 
      </v-toolbar>
        <!-- <v-img class="mb-4"
          :src="generateLogoUrl(item.module.logo)"
          max-height="15vh"
          max-width="10vw"
          contain
        >
        </v-img> -->
        <!-- <pre>{{ item.module.module.logo }}</pre> -->
        <planete-icon class="mb-2"
        :dark="$vuetify.theme.dark" 
        :class="{'icon-small': modulesView == 0, 'icon-x-small': modulesView == 1}"
        :key="$uuid.v4()">{{ item.module.logo  || "planete-online" }}</planete-icon>
        <v-row class="d-flex justify-space-between align-center px-1"
          style="width: 100%;"
        >
        <v-col cols="10">
          <span class="text--primary">{{ item.sous_module.length }} Sous modules </span>
        </v-col>
        <v-col cols="2">
          <v-menu v-model="showStates[index]" :close-on-content-click="false" transition="scale-transition" max-height="400">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" v-if="item.sous_module.length > 0">
                <v-icon class="mr-2"
                color="blue lighten-1"  
                title="Liste des sous-modules"
                >mdi-format-list-bulleted-square</v-icon>
              </v-btn>
              <v-btn icon v-else>
                <v-icon class="mr-2"
                  icon
                  color="grey lighten"
                  title="Aucun sous-module"
                >
                mdi-information-off-outline</v-icon>
              </v-btn>
            </template>
            <v-list dense min-width="250">
              <v-list-item-group>
                  <v-list-item v-for="sousModule in item.sous_module" :key="sousModule._id">
                      <v-list-item-content v-if="item.sous_module.length > 0" @click="searchEl(sousModule.sous_module, 'link')">
                          <v-list-item-title class="text-h7 text-capitalize">
                              <span v-html="sousModule.sous_module"></span>
                              <span v-if="sousModule.version"> - v{{ sousModule.version }}</span>
                          </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content v-else-if="item.sous_module.length === 0">
                          <v-list-item-title class="text-h7 text-capitalize" v-html="'Aucun sous-module'">
                          </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                          <v-icon color="grey">mdi-bookmark</v-icon>
                      </v-list-item-icon>
                  </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
        </v-row> 
        <v-card-actions class="d-flex justify-space-between"
          style="width: 100%;"
        >
          <v-btn
            text
            color="purple lighten-1"
            @click="showDescription(index)"
          >
            Description
          </v-btn>
          <div>
            <v-menu 
              v-model="menuEditStatesModule[item._id]" 
              bottom
              origin="center center"
              :close-on-content-click="false" 
              transition="scale-transition" 
              max-width="400"
              >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  color="red accent-1"
                  icon
                  title="Editer"
                  v-on="on"
                  @click="openEditModule(item._id, index)"
                >
                <v-icon size="20">mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <v-card>
                  <v-card-title>
                    <span class="text-h5">Editer le module</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            label="Module"
                            v-model="datamoduleEdit.module"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            label="Lien vers le référentiel Git"
                            v-model="datamoduleEdit.lienGit"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12">
                          <v-text-field
                            label="Description"
                            v-model="datamoduleEdit.description"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12">
                          <v-file-input
                            type="file"
                            @change="handleLogoChange"
                            :value="datamoduleEdit.logo"
                            accept="image/*"
                            label="Logo*"
                          ></v-file-input>
                        </v-col>
                        <v-row v-if="newLogoPreview">
                          <v-img 
                            :src="newLogoPreview" 
                            height="10vh"
                            width="10vw"
                            contain
                          >
                          </v-img>
                        </v-row> -->
                      
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue-darken-1"
                      variant="text"
                      @click="closeEdit(item._id)"
                    >
                      Annuler
                    </v-btn>
                    <v-btn
                      color="warning"
                      variant="text"
                      @click="saveEditModule(item._id, index)"
                    >
                      Enrengristrer
                    </v-btn>
                  </v-card-actions>
                </v-card>
            </v-menu> 
          <v-btn 
            color="red accent-3"
            @click="openDeleteModule(item._id)" 
            text
            icon
            title="Supprimer"
          >
            <v-icon size="20">mdi-delete</v-icon>
          </v-btn>
          </div>
          
        </v-card-actions>
        <v-expand-transition>
            <v-card
              v-if="revealStates[index]"
              class="transition-fast-in-fast-out v-card--reveal d-flex align-center justify-space-between flex-column"
              style="height: 100%;"
            >
              <v-card-text class="pb-0">
                <p class="text-h5 text--primary text-capitalize text-center">
                  {{ item.module.module }}
                </p>
                <p>
                  {{ item.module.description }}
                </p>
              </v-card-text>
              <v-card-actions class="d-flex justify-start" 
                style="width: 100%;"
              >
                <v-btn
                  text
                  color="purple lighten-1"
                  @click="showDescription(index)"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-expand-transition>
      </v-card>
    </v-container>
    <!-- card avec la liste des sous modules -->
    <!-- si aucune data afficher un message -->
    <v-alert 
      v-if="dataSousModule.length === 0 && !show" 
      type="info" 
      outlined
    >
      Aucun sous-module
    </v-alert>
    <v-container v-if="show || isSearch"
      fluid class="module-grid" :class="{'small': modulesView == 0, 'x-small': modulesView == 1}">
      <v-card class="d-flex flex-column pa-2" 
        v-for="(item, index) in dataSousModule.flat()" :key="item._id"
      > 
        <v-row class="d-flex justify-space-between align-center"
          style="flex-wrap: nowrap;"
        > 
          <v-card-title style="word-break: break-word;"
            :class="{'text-h7 text-capitalize': modulesView == 1, 'text-h6 text-capitalize': modulesView == 0}"
          >
          {{ item.sous_module }}
          </v-card-title>
          <!-- icon back arrow -->
          <v-btn class="mr-2 elevation-2"
              v-if="isBackBtn"
              icon
              style="background-color: #7b4e8e;"
              @click="searchEl('', 'link')"
            >
              <v-icon color="white" title="retour">mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            v-if="!isBackBtn && loading"
            class="spin-load mr-2"
            icon
            style="background-color: #7b4e8e;"
          >
            <v-icon color="white" title="chargement">mdi-loading</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-card-text> 
            <v-row class="d-flex justify-space-between flex-column mx-1">
                <p>
                  Technologie: 
                  <span class="text-uppercase text--secondary">{{ item.technologie }}</span>
                </p>
                <div class="d-flex justify-space-between">
                  <p>Version: <span class="text-uppercase text--secondary">{{ item.version }}</span> </p>
                  <!-- si datefin -->
                  <p v-if="item.dateFin" style="font-style: italic; font-size: small;">
                    Date de fin: <span class="text-uppercase text--secondary">{{ item.dateFin }}</span>
                  </p>
                </div>
              </v-row>
              <v-row class="d-flex justify-space-between flex-column mx-1 mb-3">
                <v-text-field   v-if="item.sousModuleGit" 
                readonly
                dense
                outlined
                :value="item.sousModuleGit"
                hide-details
              >
              <!-- onclick sur l icon -->
              <template v-slot:append
              >
                <v-btn icon @click="openLink(item.sousModuleGit)">
                  <v-icon
                  v-if="item.sousModuleGit" 
                    color="blue" 
                    title="Lien vers le référentiel Git"
                    class="mb-2"
                  >mdi-git</v-icon>
                </v-btn>
              </template>
                </v-text-field>
              <v-text-field v-else-if="!item.sousModuleGit" 
                readonly
                dense
                outlined
                :value="'Aucun référentiel Git'"
                hide-details
                append-icon="mdi-information-off-outline"
              >
              </v-text-field>
              </v-row>
              <v-row class="d-flex justify-space-between align-center mx-1 mt-1">
                <p style="margin: 0;">
                  Développeur:
                </p>
                <v-btn  
                  icon
                  @click="showExpension(index)"
                >
                  <v-icon>{{ showStates[index] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-row>

            <v-expand-transition>
              <div v-show="showStates[index]"
              class="expension-user"
              >  
                <span class="text-uppercase text--secondary">{{ item.users && item.users.join(', ') }}</span>
              </div>
            </v-expand-transition>
          </v-card-text>
        </v-row>
        <v-row class="mt-0">  
          <v-card-actions class="d-flex justify-space-between"
            style="width: 100%;"
          >
            <v-btn
              text
              color="purple lighten-1"
              @click="showDescription(index)"
            >
              Description
            </v-btn> 
            <div> 
              <!-- menu d edition du sous module -->
              <v-menu 
                v-model="menuEditStatesSousModule[index]" 
                bottom
                origin="center center"
                :close-on-content-click="false" 
                transition="scale-transition" 
                max-width="500"
                >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    color="red accent-1"
                    icon
                    title="Editer"
                    v-on="on"
                    @click="openEditsousModule(item.idSousModule, index)"
                  >
                  <v-icon size="20">mdi-square-edit-outline</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Editer le sous-module</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            label="Sous-module"
                            v-model="dataSousModuleEdit.sous_module"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="7">
                          <v-autocomplete
                            ref="userSelect"
                            :items="users"
                            :item-text="item => `${item.firstname} ${item.lastname}`"
                            :label="'Developpeur(s)'"
                            multiple
                            v-model="dataSousModuleEdit.users"
                          >
                            <template slot="selection" slot-scope="data">
                              {{ data.item.firstname + ' ' + data.item.lastname }}
                            </template>
                            <template slot="item" slot-scope="data">
                              {{ data.item.firstname + ' ' + data.item.lastname }}
                            </template>
                          </v-autocomplete> 

                        </v-col>
                        <!-- date picker pour saisir une date fin -->
                        <v-col cols="5">
                          <v-menu>
                            <template v-slot:activator="{ on }">
                              <v-text-field 
                              :value="dataSousModuleEdit.dateFin"
                              label="Date de fin"
                              v-on="on"
                            >
                          </v-text-field>
                            </template>
                            <v-date-picker 
                              style="width: 100%" 
                              v-model="dataSousModuleEdit.dateFin"
                              :locale="$i18n.locale" 
                              color="primary"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="8">
                          <v-text-field
                            label="Technologie"
                            v-model="dataSousModuleEdit.technologie"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Version"
                            v-model="dataSousModuleEdit.version"
                          ></v-text-field>
                        </v-col>
                        <!-- referenciel git -->
                        <v-col cols="12">
                          <v-text-field
                            label="Lien vers le référentiel Git"
                            v-model="dataSousModuleEdit.sousModuleGit"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            label="Description"
                            v-model="dataSousModuleEdit.sousModuleDescription"
                            rows="3"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue-darken-1"
                      variant="text"
                      @click="closeEdit(index)"
                    >
                      Annuler
                    </v-btn>
                    <v-btn
                      color="warning"
                      variant="text"
                      @click="saveEditSousModule(item.idSousModule)"
                    >
                      Enrengristrer
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>

              <v-btn
                text
                color="red accent-3"
                title="Supprimer"
                @click="openDeleteSousModule(item.idSousModule)"
                icon
              >
                <v-icon size="20">mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-card-actions>
        </v-row>

        <v-row>
          <v-expand-transition>
            <v-card
              v-if="revealStates[index]"
              class="transition-fast-in-fast-out v-card--reveal d-flex align-center justify-space-between flex-column"
              style="height: 100%;"
            >
              <v-card-text class="pb-0">
                <p class="text-h5 text--primary text-capitalize">
                  {{ item.sous_module }}
                </p>
                <p>
                  {{ item.sousModuleDescription }}
                </p>
              </v-card-text>
              <v-card-actions class="d-flex justify-start" 
                style="width: 100%;"
              >
                <v-btn
                  text
                  color="purple lighten-1"
                  @click="showDescription(index)"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expand-transition>
        </v-row>
      </v-card>
    </v-container>
    <!-- modale de creation module -->
    <v-dialog v-model="dialogModule" max-width="50%" persistent>
      <ModuleCreate @refresh="loadData" @close-modal-create="dialogModule = false"/>
    </v-dialog>
    <!-- modale de creation sous-module -->
    <v-dialog v-model="dialogSousModule" max-width="50%">
      <SousModuleCreate @refresh="loadData" persistent @close-modal-create-sous-module="closeModalSousModule"/>
    </v-dialog>
    <!-- modale d'edition module -->
    <v-dialog v-model="dialogEdit" max-width="50%" persistent>
      <AddSousModule @refresh="loadData" @close-edit="closeEdit"/>
    </v-dialog>
    <!-- modale de suppression de sous-module -->
    <v-dialog v-model="dialogDeleteSousModule" max-width="30%">
      <DeleteSousModule @sousModuleDeleted="loadData" @close-delete-modal="closeDeleteSousModule"/>
    </v-dialog>
    <!-- modale de suppression de module -->
    <v-dialog v-model="dialogDeleteModule" max-width="30%">
      <DeleteModule @moduleDeleted="loadData" @close-delete-modal="closeDeleteModule"/>
    </v-dialog>
  </v-container>  
</template>
<script>

import UserService from '@/Services/UserService';
import * as Vuex from "vuex";
import ModuleService from "@/Services/SupportVision/ModuleService";
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import Images from '@/Utils/Images';

export default {
  name: "Index",
  components: {
    ClientPickerSelect: () => import("@/Components/Commons/Inputs/Client/ClientPickerSelect"),
    ModuleCreate: () => import("@/Components/Views/SupportVision/Modules/createModule"),
    SousModuleCreate: () => import("@/Components/Views/SupportVision/Modules/createSousModule"),
    AddSousModule: () => import("@/Components/Views/SupportVision/Modules/addSousModule"),
    // SousModuleEdit: () => import("@/Components/Views/SupportVision/Modules/editSousModule"),
    DeleteSousModule: () => import("@/Components/Views/SupportVision/Modules/sousModuleDelete"),
    DeleteModule: () => import("@/Components/Views/SupportVision/Modules/moduleDelete"),
  },
  data() {
    return {
      dialogModule: false,
      dialogEdit: false,
      dialogSousModule: false,
      dialogListeSousModule: false,
      menuEditStatesModule: [],
      menuEditStatesSousModule: [],
      dialogDeleteSousModule: false,
      dialogDeleteModule: false,
      dialogModuleEdit: false,
      reveal: false,
      isSousModule: false,
      show: false,
      dataModule: [],
      dataSousModule: [],
      location: '',
      search: '', 
      isSearch: false,
      showStates: [],
      revealStates: [],
      loading: false,
      moduleSort: null,
      sorts: [
        { title: 'Par défaut', value: 'default' },
        { title: 'Ordre alphabétique : A-Z', value: 'asc' },
        { title: 'Ordre alphabétique : Z-A', value: 'desc' },
      ],
      datamoduleEdit: {
        module: '',
        description: '',
        logo: null,
      },
      dataSousModuleEdit: {
        sous_module: '',
        description: '',
        technologie: '',
        sousModuleGit: '',
        version: '',
        users: [],
        dateFin: null,
      },
      newLogoPreview: null,
      isBackBtn: false,
    }
  }, 
  computed: {
    ...Vuex.mapState(["modules", "preferences", "informations"]),
    ...Vuex.mapMutations(["setModuleId", "setSousModuleId", "setModuleItem"]),
    modulesView: {
      get() {
        return this.preferences.modulesView
      },
      set(val) {
        this.$store.commit("switchModulesView", {val: val})
        let store = this.$_.pick(this.$store.state, ["preferences"])
        PlaneteOnlineService.postInterface(store)
      }, 
    },  
  },
  watch: {
    search() {
      this.debounceSearch();
    },   
  },
  methods: {
    async handleLogoChange(file) {
      if (file) {
        let b64 = await Images.toBase64(file);
        this.newLogoPreview = b64;
        this.datamoduleEdit.logo = file
      } else {
        this.newLogoPreview = null;
        this.datamoduleEdit.logo = null;
      }
    },
    isActive(id) {
      return this.activeId === id;
    },
    setActive(id) {
      this.activeId = id;
    },
    resetActive() {
      this.activeId = null;
    },
    openModalModule() {
      this.dialogModule = true
    }, 
    openModalSousModule() {
      this.dialogSousModule = true
    }, 
    closeModalSousModule() {
      this.dialogSousModule = false
    },
    openListSousModule() {
      this.show = !this.show
    },
    openLink(link) {
      window.open(link, '_blank');
    },
    async openEditModule(id, index) {
      this.$store.commit('setModuleId', id);
      const res = await ModuleService.getModuleById(this.$store.state.moduleId);
      this.datamoduleEdit = res.module;
      // Mettre à jour l'état du menu d'édition pour cet élément
      this.menuEditStatesModule[id] = true;

    },
    addSousModule(id, item) {
      this.$store.commit('setModuleId', id);
      this.$store.commit('setModuleItem', item);
      this.dialogEdit = true
    },
    async openEditsousModule(id, index) {
      this.$store.commit('setSousModuleId', id);
      const res = await ModuleService.getSousModuleById(this.$store.state.sousModuleId);
      this.dataSousModuleEdit = res[0]
      // Mettre à jour l'état du menu d'édition pour cet élément
      this.menuEditStatesSousModule[index] = true;
    },
    closeEdit(id) { 
      // Fermer le menu d'édition pour cet élément 
      this.dialogEdit = false; 
      this.$set(this.menuEditStatesSousModule, id, false);
      this.$set(this.menuEditStatesModule, id, false);
      
    }, 
    openDeleteSousModule(id) {
      this.$store.commit('setSousModuleId', id);
      this.dialogDeleteSousModule = true
    },
    closeDeleteSousModule() {
      this.dialogDeleteSousModule = false
      this.isBackBtn = false;
      this.show = false;
      this.isSearch = false;
    },
    openDeleteModule(id) {
      this.$store.commit('setModuleId', id);
      this.dialogDeleteModule = true
    },
    closeDeleteModule() {
      this.dialogDeleteModule = false
    },
    async saveEditModule(id, index) {
      const formData = new FormData();
      formData.append('module', this.datamoduleEdit.module);
      formData.append('description', this.datamoduleEdit.description);
      // formData.append('logo', this.datamoduleEdit.logo);
      formData.append('lienGit', this.datamoduleEdit.lienGit);
      await ModuleService.updateModule(id, formData);
      this.menuEditStatesModule[index] = false; 
      this.loadData();
    },
    async saveEditSousModule(id) {
      const data = {
        sous_module: this.dataSousModuleEdit.sous_module,
        description: this.dataSousModuleEdit.sousModuleDescription,
        technologie: this.dataSousModuleEdit.technologie,
        version: this.dataSousModuleEdit.version,
        users: this.dataSousModuleEdit.users,
        dateFin: this.dataSousModuleEdit.dateFin,
        sousModuleGit: this.dataSousModuleEdit.sousModuleGit,
      }
      await ModuleService.updateSousModule(id, data);
      this.loadData();
    },
    async loadData() {
      const users = await UserService.getUsers().then((res) => {
        this.users = res;
      });
      this.loading = true;
      const dataModule = await ModuleService.getModules();
      this.dataModule = dataModule.data;
      const dataUrl = dataModule.config.baseURL + dataModule.config.url;
      this.location = dataUrl;
      // const res = await ModuleService.getSousModules();
      this.dataSousModule = dataModule.data.map((sousModule) => sousModule.sous_module);
      this.loading = false;
      // Initialiser le tableau revealStates avec false pour chaque élément
      this.revealStates = new Array(this.dataSousModule.length).fill(false);
      this.showStates = new Array(this.dataModule.length).fill(false);
      this.menuEditStatesModule = new Array(this.dataModule.length).fill(false);
    },
    generateLogoUrl(logo) {
      if (logo && logo.filename && logo.mimetype) {
        return `${this.location}/read-file?id=${logo.filename}&mime=${logo.mimetype}`;
      }
      return ''; 
    }, 
    showExpension(index) {
      this.$set(this.showStates, index, !this.showStates[index]); 
    },
    showDescription(index) {
      this.$set(this.revealStates, index, !this.revealStates[index]);
    }, 
    async searchEl(query, link) {
      link === 'link' ? this.isBackBtn = !this.isBackBtn : this.isBackBtn = false;
      query = query.toLowerCase();
      if (query === '') {
        this.loading = true;
        await this.loadData();
        this.isSearch = false;
        this.loading = false;
        return;
      }
      const data = await ModuleService.searchElement(query);
      this.dataModule = data.data.modules;
      this.dataSousModule = data.data.sous_modules;
      this.isSearch = true;
    },
    debounceSearch() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(() => {
        this.searchEl(this.search);
      }, 300); // Délai de 300 millisecondes 
    },
    sortDataByTitle(order) {
      if (order === 'asc') {
        // Tri par ordre alphabétique croissant
        this.dataModule.sort((a, b) => a.module.module.localeCompare(b.module.module));
        this.dataModule.forEach(mod => {
          mod.sous_module.sort((a, b) => a.sous_module.localeCompare(b.sous_module));
        });
      } else if (order === 'desc') {
        // Tri par ordre alphabétique décroissant
        this.dataModule.sort((a, b) => b.module.module.localeCompare(a.module.module));
        this.dataModule.forEach(mod => {
          mod.sous_module.sort((a, b) => b.sous_module.localeCompare(a.sous_module));
        });
      } else if (order === 'default'){
        // Tri par défaut
        this.loadData();
        
      }
    },
    async createModulesAndSubmodules(moduleResponse) {
      for (const moduleId in moduleResponse) {
        const module = moduleResponse[moduleId];
        // Créer le module
        const newModule = {
            id: module.id,
            module: {
                module: module.name,
                logo: module.icon,
                lienGit: module.git,
                description: "module.description",
              },
            sous_module: [],
        };
        // Ajouter les sous-modules au module
        if (module.subModules && Array.isArray(module.subModules)) {
            module.subModules.forEach(subModule => {
              newModule.sous_module.push({
                dateAjout: null,
                idSousModule: subModule.id,
                sous_module: subModule.name,
                sousModuleGit: subModule.path,
                version: "1",
                dateFin: null,
                technologie: "technologie",
                users: ["subModule.users"],
                sousModuleDescription: "subModule.description",
            });
          });  
          this.dataModule.push(newModule)
          this.dataSousModule.push(newModule.sous_module);
          // save en base de données
          await ModuleService.createModule(newModule);
        }
      }
    },

  },
  async mounted() { 
    await this.loadData();
    // Chargement des modules en store
    // this.createModulesAndSubmodules(this.modules);
  }
}
</script>

<style scoped>

.icon-small {
    width: 10vw;
    height: 10vh;
  }
  .icon-x-small {
    width: 5vw;
    height: 5vh;
  }
.spin-load {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
.skeleton-loader {
  min-width: 15vh;
  max-width: 45vh;
}
.expension-user {
 /* forcer a respecter la width du parent */
  max-width: 300px;
  min-width: 100px;
  max-height: 100%;
  overflow :scroll;
  word-break: break-word;
  margin-top: 8px;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.module-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2em;
}

.module-grid.small {
  grid-template-columns: repeat(8, 1fr);
}

.module-grid.x-small {
  grid-template-columns: repeat(12, 1fr);
}

@media (max-width: 599px) {
  .module-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
  }

  .module-grid.small {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
  }

  .module-grid.x-small {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .module-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
  }

  .module-grid.small {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
  }

  .module-grid.x-small {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
  }
}

@media (min-width: 960px) and (max-width: 1263px) {
  .module-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
  }

  .module-grid.small {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
  }

  .module-grid.x-small {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1em;
  }
}

@media (min-width: 1264px) and (max-width: 1903px) {
  .module-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1em;
  }

  .module-grid.small {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1em;
  }

  .module-grid.x-small {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1em;
  }
}

@media (min-width: 1904px) {
  .module-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1em;
  }

  .module-grid.small {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1em;
  }

  .module-grid.x-small {
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1em;
  }

  .switch-selected {
    color: var(--v-primary-base) !important;
  }
  
}
</style>